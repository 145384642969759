import { createGlobalStyle } from 'styled-components'
import { mediaMax, calculateResponsiveSize } from 'styles/media-queries'

const GlobalStyle = createGlobalStyle`
  :root {
    --blue: #0033A0;
    --white: #FFFFFF;
  }
  li {
    list-style: none;
  }
  * {
    margin: 0;
    padding: 0;
    font-family: inherit;
    box-sizing: border-box;
  }
  body {
    width: 100%;
    height: 100%;

    font-family: "Berthold Akzidenz Grotesk";
    font-size: ${calculateResponsiveSize(12)};
    font-weight: 700;
    line-height: ${calculateResponsiveSize(18)};
    letter-spacing: 0.1em;

    color: var(--blue);
    background: var(--white);
    min-width: 320px;
    overflow-y: scroll;
    ${mediaMax('mobile')} {
      font-size: 12px;
    line-height: 18px;
    }
  }
  body.hidden {
    height: 100dvh !important;
  }
  body.hidden .home-wrapper {
    height: 100dvh;
    overflow: hidden;
  }

  a, a:hover, a:active, a:visited {
    color: var(--blue);
    text-decoration: none;
  }

  @keyframes fadeIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  .home-content {
    will-change: transform;
  }
`

export default GlobalStyle
